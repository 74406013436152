 <template>
	<div class="token-management" ref="tokenManagement">
		<el-table ref="multipleTable" :data="tableData" style="width: 100%" stripe :height="tableHeight">
			<el-table-column prop="user_id" label="用户ID" :show-overflow-tooltip="true"></el-table-column>
			<el-table-column prop="username" label="用户名" :show-overflow-tooltip="true"></el-table-column>
			<el-table-column prop="realname" label="姓名" :show-overflow-tooltip="true"></el-table-column>
			<el-table-column  label="状态" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{scope.row.active===true?"存活":"过期"}}</span>
                </template>
            </el-table-column>
			<el-table-column prop="scope" label="权限范围" :show-overflow-tooltip="true"></el-table-column>
			<el-table-column  label="过期时长" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{formateSeconds(scope.row.expires_in)}}</span>
                </template>
            </el-table-column>
			<el-table-column prop="token_type" label="Authorization Type" :show-overflow-tooltip="true"></el-table-column>
			<el-table-column prop="access_token" label="Token" :show-overflow-tooltip="true"></el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button
						icon="el-icon-delete"
						type="text"
						size="small"
						sort="danger"
						@click="onDeletetoken(scope.row)"
					>注销用户</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination" ref="pagination">
			<el-pagination
				background
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
				:page-size="form.pageSize"
				:current-page="form.currentPage"
				@size-change="onSizeChange"
				@current-change="onCurrentChange"
			></el-pagination>
		</div>
		<!-- <el-diatoken :title="title" v-if="diatokenVisible" :visible.sync="diatokenVisible" width="40%">
			<div>
				<div class="body"></div>
				<tokenInfo ref="tokenInfo" :tokenId="tokenId" @onDiatokenClose="onDiatokenClose" />
			</div>
			<span slot="footer" class="diatoken-footer">
				<el-button type="default" size="small" @click="diatokenVisible = false">关闭</el-button>
			</span>
		</el-diatoken> -->
	</div>
</template>

  <script>
// import addtoken from "./components/addtoken.vue";
import { getTokensPage, deleteTokens } from "@/api/lib/api.js";
import {formateSeconds} from "@/common/utils/index.js";
export default {
	name: "tokenManagement",
	data() {
		return {
			tableHeight: 0,
			total: 10,
			tableData: [],
			form: {
				current: 1,
				size: 10,
			},
			multipleSelection: [],
			title: "",
			tokenId: null,
			diatokenVisible: false,
		};
	},
	methods: {
		// 计算表格高度
		computeHeight() {
			let wholeHeight = this.$refs.tokenManagement.clientHeight;
			let paginationHeight = this.$refs.pagination.clientHeight;
			this.tableHeight =
				wholeHeight - 16  - paginationHeight - 10 - 16 - 35;
		},
		// 点击查询
		onSearch() {
			this.form.current = 1;
			this.getTokenListByField(this.form);
		},
		//获取数据
		getTokenListByField(data) {
			getTokensPage(data).then((res) => {
				if (res.code === 0) {
					this.tableData = res.data.records;
					this.total = res.data.total;
					if (!res.data.records.length && this.form.current !== 1) {
						//如果当前页的数据为0则设置页面为前一页 但是确保当前页不为1
						self.form.current -= 1;
						self.getTokenListByField(this.form);
					}
				} else {
					this.tableData = [];
					this.total = 0;
					this.$message.error(res.message);
				}
			});
		},
        //
        onDeletetoken(row){
            const h = this.$createElement;
			this.$msgbox({
				title: "消息",
				type: "warning",
				message: h("p", null, [
					h("span", null, "确定删除 "),
					h("i", { style: "color: #059370" }, row.name),
					h("span", null, " ？"),
				]),
				showCancelButton: true,
				confirmButtonText: "确定",
				cancelButtonText: "取消",
			})
				.then(() => {
					deleteTokens(row.access_token).then((res) => {
						if (res.code === 0) {
							this.$message.success("注销成功");
							this.getlogListByField(this.form);
						} else {
							this.$message.error("注销失败");
						}
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						showClose: true,
						message: "已取消操作",
					});
				});
        },
		
		// 当前页码改变
		onCurrentChange(page) {
			this.form.current = page;
			this.getTokenListByField(this.form);
		},
		// 当前条数改变
		onSizeChange(size) {
			this.form.size = size;
			this.getTokenListByField(this.form);
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		// 弹窗关闭时的回调
		onDiatokenClose(flag) {
			this.diatokenVisible = false;
        },
        //时间转换
        formateSeconds(time){
            return formateSeconds(time);
        }
	},
	created() {
		this.onSearch();
	},
	mounted() {
		this.$nextTick(() => {
			this.computeHeight();
			window.addEventListener("resize", this.computeHeight, false);
		});
	},
	destroyed() {
		window.removeEventListener("resize", this.computeHeight);
	},
};
</script>
<style lang="scss" scoped>
.token-management {
	width: 100%;
	height: 100%;
	border-radius: 4px;
	padding: 16px 20px;
	box-sizing: border-box;
	.avatarImg {
		vertical-align: middle;
		border-radius: 50%;
	}
}

.theme-light {
	.token-management {
		background: #ffffff;
		box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
	}
}
</style>